import React from 'react';
import { NextPage } from 'next';
import CautionIcon from '@b2b-frontend/components/icons/CautionIcon';
import Button from '@b2b-frontend/components/Button';
import MaxWidthContainer from '../components/MaxWidthContainer';
import Heading from '../components/Heading';

const NotFoundError: NextPage = () => {
	return (
		<MaxWidthContainer className="flex flex-col items-center justify-center py-20">
			<CautionIcon className="mb-3" />
			<Heading className="mb-4">404</Heading>
			<p className="text-xs mb-10">Sorry! The page you&apos;re looking for was not found</p>
			<Button href="/">Home</Button>
		</MaxWidthContainer>
	);
};

export default NotFoundError;
